/* eslint-disable camelcase */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';

export const getUsersAll = createAsyncThunk(
  'selectsData/users/getUsersAll',
  async (wsId, { dispatch, getState }) => {
    const { isUsersLoaded, client } = getState().selectsData.users;
    if (!isUsersLoaded || client !== null) {
      const response = await axios.get(ApiRoutes.usersEndPoint(wsId));
      const data = await response.data;
      dispatch(setUsersProps({ client: null }));

      dispatch(setIsUsersLoaded(true));
      return data;
    }
    return [];
  }
);

export const getUsersAllPerClient = createAsyncThunk(
  'selectsData/users/getUsersAllPerClient',
  async ({ wsId, client_id }, { dispatch, getState }) => {
    await dispatch(setIsUsersLoaded(false));

    const { data } = await axios.get(ApiRoutes.usersEndPoint(wsId, { client_id }));
    dispatch(setUsersProps({ client: client_id }));

    await dispatch(setIsUsersLoaded(true));
    return data;
  }
);

const usersAdapter = createEntityAdapter({});

export const selectUserSelector = (state) => state.selectsData.users;

export const { selectAll: selectUsers, selectById: selectUserById } =
  usersAdapter.getSelectors(selectUserSelector);

const initialState = usersAdapter.getInitialState({
  isUsersLoaded: false,
  client: null,
});

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setIsUsersLoaded: (state, action) => {
      state.isUsersLoaded = action.payload;
    },
    setUsersProps: (state, action) => {
      const { client = null } = action.payload;
      state.client = client;
    },
    resetUserState: () => initialState,
  },
  extraReducers: {
    [getUsersAll.fulfilled]: usersAdapter.addMany,
    [getUsersAllPerClient.fulfilled]: usersAdapter.setAll,
  },
});

export const { setIsUsersLoaded, resetUserState, setUsersProps } = userSlice.actions;

export default userSlice.reducer;
