import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';

export const getNotifications = createAsyncThunk(
  'notificationPanel/getNotifications',
  async ({ ws }, { dispatch }) => {
    dispatch(setLoading(true));
    const response = await axios.get(ApiRoutes.notificationsEndPoint(ws));
    // check all notifications
    await axios.put(`${ApiRoutes.notificationsEndPoint(ws)}unchecked/`, {});
    dispatch(setNotificationCount(0));

    const data = await response.data.results;
    dispatch(setLoading(false));

    return data;
  }
);

const notificationsAdapter = createEntityAdapter({});

const initialState = notificationsAdapter.getInitialState({
  open: false,
  loading: true,
  notificationCount: 0,
});

export const selectNotificationPanelState = (state) => state.notificationPanel;

export const { selectAll: selectNotifications, selectById: selectNotificationsById } =
  notificationsAdapter.getSelectors((state) => state.notificationPanel);

const NotificationSlice = createSlice({
  name: 'notificationPanel',
  initialState,
  reducers: {
    setNotificationCount: (state, action) => {
      state.notificationCount = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    toggleNotificationPanel: (state, action) => {
      state.open = !state.open;
    },
    openNotificationPanel: (state, action) => {
      state.open = true;
    },
    closeNotificationPanel: (state, action) => {
      state.open = false;
    },
  },
  extraReducers: {
    [getNotifications.fulfilled]: notificationsAdapter.addMany,
  },
});

export const {
  toggleNotificationPanel,
  openNotificationPanel,
  closeNotificationPanel,
  setNotificationCount,
  setLoading,
} = NotificationSlice.actions;

export default NotificationSlice.reducer;
