/* eslint-disable camelcase */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { setNotificationCount } from 'app/theme-layouts/shared-components/notificationPanel/store/NotificationSlice';
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';

export const getNotifications = createAsyncThunk(
  'managementApp/notifications/getNotifications',
  async ({ wsId, params }, { dispatch, getState }) => {
    dispatch(setIsLoading(true));

    const response = await axios.get(ApiRoutes.notificationsEndPoint(wsId, params));
    // check all notifications
    await axios.put(`${ApiRoutes.notificationsEndPoint(wsId)}unchecked/`, {});
    dispatch(setNotificationCount(0));

    dispatch(setIsLoading(false));
    dispatch(setPage(params.page));

    dispatch(setNumOfPages(Math.ceil(response.data.count / 100 || 0)));
    dispatch(setCount(response.data.count));

    const data = await response.data.results;

    return data;
  }
);

export const getNotification = createAsyncThunk(
  'managementApp/notification/getNotification',
  async ({ wsId, id }, { getState, dispatch }) => {
    const { entities } = getState().managementApp.notification;

    if (entities[id]) return entities[id];

    const { data } = await axios.get(ApiRoutes.notificationsEndPoint(wsId, null) + id);

    return data;
  }
);

export const setNotification = createAsyncThunk(
  'managementApp/notifications/setNotification',
  async (data) => {
    return { id: data.id, changes: data };
  }
);

const notificationsAdapter = createEntityAdapter({});

export const { selectAll: selectNotifications, selectById: selectNotificationById } =
  notificationsAdapter.getSelectors(({ managementApp }) => managementApp.notifications);

export const selectNotificationData = ({ managementApp }) => managementApp.notifications;

const initialState = notificationsAdapter.getInitialState({
  numOfPages: 0,
  count: 0,
  page: 1,
  isLoading: true,
  FiltersDialogProps: {
    open: false,
  },
});

const notificationsSlice = createSlice({
  name: 'managementApp/notifications',
  initialState,
  reducers: {
    setNumOfPages: (state, action) => {
      state.numOfPages = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    openFiltersDialog: (state, action) => {
      state.FiltersDialogProps = {
        open: true,
      };
    },
    closeFiltersDialog: (state, action) => {
      state.FiltersDialogProps = {
        open: false,
      };
    },
    resetNotificationsState: () => initialState,
  },
  extraReducers: {
    [getNotifications.fulfilled]: notificationsAdapter.setAll,
    [setNotification.fulfilled]: notificationsAdapter.updateOne,
    [getNotification.fulfilled]: notificationsAdapter.upsertOne,
  },
});

export const selectSelectedNotification = ({ managementApp }) =>
  managementApp.notifications.selectedNotificationId;

export const {
  setNumOfPages,
  setIsLoading,
  setPage,
  resetNotificationsState,
  setCount,
  openFiltersDialog,
  closeFiltersDialog,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
