const locale = {
  ASSIGNMENTS: 'Assignments',
  AUTO_START: 'Auto start',
  AUTO_START_DESP:
    'Set a schedule to automatically start and stop real-time location at configured times',
  AUTOMATIC_SYNCHRONIZATION_THRESHOLD: 'Automatic synchronization threshold',
  AUTOMATIC_SYNCHRONIZATION_THRESHOLD_DESP:
    'The minimum number of persistent records to accumulate before triggering an auto-sync action.',
  CARD_BODY_FIELDS: 'Order of the data in the assignment list',
  CARD_BODY_FIELDS_DESP:
    'You can indicate the order of the data to be displayed on the assignment screen ',
  CLIENT: 'Client',
  CLIENT_NAME: 'Client name',
  COUNTRY: 'Country',
  CREATE_ORDER_FIELDS: 'Order of the fields in the creation of a task ',
  CREATE_ORDER_FIELDS_DESP:
    'You can indicate the order of the fields on the screen where the tasks are created',
  DATE: 'Date',
  DATE_DUE: 'Due date',
  DAYS_APPLY_AUTO_START: 'Days that auto start applies',
  DAYS_APPLY_AUTO_START_DESP:
    'Indicate which days the auto-ignition of the location in real time will apply.',
  DESCRIPTION: 'Description',
  DISTANCE_FILTER: 'Distance filter',
  DISTANCE_FILTER_DESP:
    'The minimum distance (measured in meters) that a device must move horizontally before an update event is generated',
  FORM_NAME: 'Form name',
  FORMAT_TITLE: 'Title format',
  FORMAT_TITLE_DESP: 'Indicate the format to follow to auto-generate the title.',
  FORMS: 'Forms',
  GENERAL: 'General',
  HIDE_BUTTON_LOGOUT: 'Hide button to log out',
  HOUR: 'Hour',
  INITIAL_POSITION_MAP: 'Initial position of the map',
  LANGUAGE: 'Language',
  LATITUDE: 'Latitude',
  LONGITUDE: 'Longitude',
  MAXIMUM_DAYS_PERSIST: 'Maximum days to persist',
  MAXIMUM_DAYS_PERSIST_DESP:
    "Maximum number of days to store a geolocation in the phone's local database when there is no connection to the server (offline mode).",
  MAXIMUM_GEOFENCE_ALLOWED: 'Maximum amount of geofences allowed',
  MAXIMUM_GEOFENCE_ALLOWED_DESP:
    'This is the maximum number of Geofences that can be created on this Desktop. If you want to increase the quantity you can contact us',
  MAXIMUM_RECORDS_PERSIST: 'Maximum records to persist',
  MAXIMUM_RECORDS_PERSIST_DESP:
    "Maximum number of records to persist in the phone's local database when there is no connection to the server.",
  MOBILE_APP_SETTINGS: 'Mobile app settings',
  NAME: 'Name',
  OBSERVATION: 'Observation',
  PROJECT: 'Project',
  REVERSE_GEOCODING: 'Reverse Geocoding',
  REVERSE_GEOCODING_DESP:
    'It allows obtaining addresses in natural language from geographic coordinates',
  SAVE: 'Save',
  SAVED_CONFIGURATION: 'Updated settings',
  SELECT_INITIAL_POSITION_MAP: 'Select the initial position of the map',
  STATUS_RULE_DESP: 'Task status changes automatically as task fields are edited.',
  STATUS_RULE_TITLE: 'Automatic status change',
  TAGS: 'Tags',
  TASK_MODULE_DESP: 'Controls whether you want to enable or disable the assignments module.',
  TASK_MODULE_TITLE: 'Task module',
  TIME_ZONE: 'Time zone',
  TITLE: 'Title',
  TITLE_AUTO_FILL_DESP: 'Autogenerate the title of the task following an indicated format.',
  TITLE_AUTO_FILL_TITLE: 'Autofill assignment title',
  TRACKING: 'Location',
  TRACKING_MODULE_DESP: 'Controls whether you want to enable or disable the Tracking module.',
  TRACKING_MODULE_TITLE: 'Location Module',
  USER: 'user',
  USER_INTERFACE: 'User interface',
  VALID_NUM_VALUE:
    'Invalid number, Valid values between {{min}} and {{max}}, default is {{default}}',
  ZOOM: 'Zoom',
  FORM_CONFIG: 'Forms configuration',
  MAX_FIELDS_PER_FORM:
    'Maximum number of fields per form. If you want to increase the quantity you can contact us',
  MAX_FIELDS_IN_WORKSPACE:
    'Maximum amount of field per desktop. If you want to increase the quantity you can contact us',
};

export default locale;
