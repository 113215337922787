import RollbarManager from 'rollbar';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
  enabled: process.env.REACT_APP_ROLLBAR_ENABLED === 'true',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    code_version: process.env.REACT_APP_VERSION_WEB,
    person: {
      id: null,
    },
    workspace: {
      id: null,
    },
  },
};

/**
 * Use this singleton to access a rollbar instance
 */
export default class Rollbar {
  static rollbar; // Rollbar instance

  /**
   * Set the singleton class instance
   * @returns {Rollbar}
   */
  constructor() {
    if (Rollbar.instance) {
      return Rollbar.instance;
    }
    Rollbar.instance = this;
    Rollbar.rollbar = new RollbarManager(rollbarConfig);
  }

  /* Use this method to get the rollbar instance */
  // eslint-disable-next-line class-methods-use-this
  getInstance() {
    return Rollbar.rollbar;
  }

  /**
   * Set the user information in the Rollbar instance
   * @param user
   */
  static setUser(user) {
    if (!user) {
      Rollbar.rollbar.configure({
        payload: {
          person: {
            id: null,
            username: null,
            email: null,
          },
        },
      });
      return;
    }

    Rollbar.rollbar.configure({
      payload: {
        person: {
          id: user.id,
          username: `${user.name} ${user.last_name}`,
          email: user.email,
        },
      },
    });
  }

  /**
   * Set the workspace information in the Rollbar instance
   * @param workspace
   */
  static setWorkspace(workspace) {
    if (!workspace) {
      Rollbar.rollbar.configure({
        payload: {
          workspace: { id: null, name: null },
        },
      });
      return;
    }

    Rollbar.rollbar.configure({
      payload: {
        workspace: {
          id: workspace.id,
          name: workspace.name,
        },
      },
    });
  }
}
