/* eslint-disable camelcase */
import Divider from '@mui/material/Divider';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import FuseUtils from '@fuse/utils';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { format, parseISO } from 'date-fns';
import { motion } from 'framer-motion';
import { roleChecker } from 'app/store/userSlice';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { selectNotificationById } from '../../store/notificationsSlice';

function NotificationSidebarContent(props) {
  const navigate = useNavigate();
  const checkRole = useSelector(roleChecker);

  const { t } = useTranslation('managementApp');
  const { notificationId } = useParams();

  const notification = useSelector((state) => selectNotificationById(state, notificationId));

  const onClose = () => navigate(`..${window.location.search}`);

  if (!notificationId || !notification) {
    return null;
  }

  return (
    <motion.div
      initial={{ y: 50, opacity: 0.8 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
      className="flex flex-col flex-1 h-full w-full"
    >
      <div className="flex items-center justify-end w-full h-full">
        <IconButton size="medium" onClick={onClose}>
          <FuseSvgIcon>heroicons-solid:x</FuseSvgIcon>
        </IconButton>
      </div>
      <div className="mt-6 px-12">
        <div>
          <div className="flex items-center justify-start w-full">
            <FuseSvgIcon
              className={clsx('text-48', FuseUtils.notificationEnum[notification.level].textColor)}
              size={20}
            >
              {FuseUtils.notificationEnum[notification.level].icon}
            </FuseSvgIcon>
            <Typography variant="subtitle1">{notification.title}</Typography>
          </div>
          <Typography className="text-10 text-grey-400">
            {t(`${notification.type.toUpperCase()}_DESP`)}
          </Typography>
        </div>
        <div className="w-full py-4">
          <Divider />
        </div>
        <Typography variant="caption">{notification.message}</Typography>

        <div className="w-full py-4">
          <Divider />
        </div>
        <div className="flex">
          <Typography className="font-medium text-10">{t('CREATION_DATE')}:</Typography>
          <Typography className="text-10">
            {format(parseISO(notification?.date_created), 'dd/MM/y hh:mm aaaa ')}
          </Typography>
        </div>
        <div className="flex">
          <Typography variant="caption" className="font-medium text-10">
            {t('CHECKED_DATE')}:
          </Typography>
          <Typography className="text-10">
            {format(parseISO(notification?.date_checked), 'dd/MM/y hh:mm aaaa ')}
          </Typography>
        </div>
        {checkRole('platform_support') && (
          <>
            <div className="flex">
              <Typography variant="caption" className="font-medium text-10">
                ID:
              </Typography>
              <Typography className="text-10">{notification?.id}</Typography>
            </div>
          </>
        )}
      </div>
    </motion.div>
  );
}

export default NotificationSidebarContent;
