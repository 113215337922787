import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import withReducer from 'app/store/withReducer';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { selectWorkspace } from 'app/store/workspaceSlice';
import NotificationCard from './NotificationCard';
import {
  closeNotificationPanel,
  selectNotificationPanelState,
  selectNotifications,
  toggleNotificationPanel,
} from './store/NotificationSlice';
import reducer from './store';

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: theme.palette.background.default,
    width: 320,
  },
}));

function NotificationPanel(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const state = useSelector(selectNotificationPanelState);
  const notifications = useSelector(selectNotifications);
  const { id } = useSelector(selectWorkspace);

  const { t } = useTranslation('navigation');

  useEffect(() => {
    if (state.open) {
      dispatch(closeNotificationPanel());
    }
    // eslint-disable-next-line
  }, [location, dispatch]);

  function handleClose() {
    dispatch(closeNotificationPanel());
  }
  const openNotifications = () =>
    window.open(`${window.location.origin}/ws/${id}/notifications/`, '_blank');

  if (state.loading) {
    return (
      <StyledSwipeableDrawer
        open={state.open}
        anchor="right"
        onOpen={(ev) => {}}
        onClose={(ev) => dispatch(toggleNotificationPanel())}
        disableSwipeToOpen
      >
        <div className=" w-full flex-1 flex justify-center items-center">
          <CircularProgress color="secondary" />
        </div>
      </StyledSwipeableDrawer>
    );
  }

  return (
    <StyledSwipeableDrawer
      open={state.open}
      anchor="right"
      onOpen={(ev) => {}}
      onClose={(ev) => dispatch(toggleNotificationPanel())}
      disableSwipeToOpen
    >
      <div className="flex flex-col  items-end py-5 pl-10">
        <div className="flex justify-between items-center w-full">
          <Typography className="text-28 font-semibold leading-none">
            {t('notifications')}
          </Typography>

          <IconButton onClick={handleClose} size="large">
            <FuseSvgIcon color="action">heroicons-outline:x</FuseSvgIcon>
          </IconButton>
        </div>
        <Typography
          className="text-12 underline cursor-pointer"
          color="secondary"
          onClick={openNotifications}
        >
          {t('SEE_ALL')}
        </Typography>
      </div>

      {notifications.length > 0 ? (
        <div className="py-16 px-8">
          <div className="flex flex-col">
            {notifications.map((item) => (
              <NotificationCard key={item.id} item={item} />
            ))}
          </div>
        </div>
      ) : (
        <div className="flex flex-1 items-center justify-center p-16">
          <Typography className="text-24 text-center" color="text.secondary">
            {t('NO_NOTIFICATIONS_TO_SHOW')}
          </Typography>
        </div>
      )}
    </StyledSwipeableDrawer>
  );
}

export default withReducer('notificationPanel', reducer)(memo(NotificationPanel));
