import { combineReducers } from '@reduxjs/toolkit';

import assignments, { resetAssignments } from './assignmentsSlice';
import forms, { resetFormsState } from './formsSlice';

const reducer = combineReducers({
  assignments,
  forms,
});

export const resetAssignmentsState = (dispatch) => {
  dispatch(resetAssignments());
  dispatch(resetFormsState());
};

export default reducer;
