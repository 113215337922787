const locale = {
  ALERT_DEMO: 'Demo',
  alerts: 'Alerts',
  assignmentList: 'Assignment list',
  assignments: 'Assignments',
  clients: 'Clients',
  COMPANY_LOGO_CAN_HERE: 'Your company logo can go here',
  csv_load: 'CSV load',
  forms: 'Forms',
  loading: 'Loading',
  location: 'Location',
  management: 'Management',
  map: 'Map',
  myProfile: 'My profile',
  organization: 'Organization',
  organizations: 'Organizations',
  projects: 'Project',
  ROLE_ORG_ADMIN: 'Administrator',
  ROLE_ORG_READ: 'Reader',
  ROLE_ORG_SUPER_ADMIN: 'Director',
  ROLE_PLATFORM_ADMIN: 'Platform Admin',
  ROLE_PLATFORM_SUPPORT: 'Support',
  ROLE_WORKSPACE: '',
  scheduled_reports: 'Scheduled reports',
  SELECT_COUNTRY: 'Select a country',
  SELECT_LANGUAGE: 'Select a language',
  SELECT_TIME_ZONE: 'Select a time zone',
  DOWNLOAD_CSV_FILE: 'Download CSV file',
  DOWNLOAD_ERROR: 'Download Error',
  DOWNLOAD_XLSX_FILE: 'Download Excel file',
  settings: 'Settings',
  SIGN_OFF: 'Sign out',
  SUPPORT_MESSAGE: 'Hello! I need support',
  SUPPORT_MESSAGE_WS: 'Hello!+I+need+support+for+the+Workspace+"{{name}}"',
  tags: 'Tags',
  users: 'User',
  workspaces: 'Workspaces',
  'USER-TO-USER_DESP': 'User-to-user notification',
  'WORKSPACE-TO-USER_DESP': 'Desktop own notification',
  'USER-LOCATION_DESP': 'Notifications regarding user locations',
  'SERVER-TO-USER_DESP': 'Notification of processes on the server to the user',
  'ASSIGNMENT-IDLENESS_DESP': 'User without tasks activity',
  NO_NOTIFICATIONS_TO_SHOW: 'There are no notifications for now.',
  notifications: 'Notifications',
  SEE_ALL: 'See All',
  DOCUMENTATION: 'Documentation',
  UPLOAD_BY_CSV: 'Load by CSV',
};

export default locale;
