/* eslint-disable camelcase */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getUsersAll } from 'app/store/selectsData/usersSlice';
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';

export const getApplications = createAsyncThunk(
  'managementApp/applications/getApplications',
  async ({ wsId, params }, { dispatch, getState }) => {
    dispatch(setIsLoading(true));

    const response = await axios.get(ApiRoutes.applicationsEndPoint(wsId, params));
    await dispatch(getUsersAll(wsId));

    dispatch(setIsLoading(false));
    dispatch(setPage(params.page));

    dispatch(setNumOfPages(Math.ceil(response.data.count / 100 || 0)));
    dispatch(setCount(response.data.count));

    const data = await response.data.results;

    return data;
  }
);

export const getApplication = createAsyncThunk(
  'managementApp/application/getApplication',
  async ({ wsId, id }, { getState, dispatch }) => {
    const { entities } = getState().managementApp.application;

    if (entities[id]) return entities[id];

    const { data } = await axios.get(ApiRoutes.applicationsEndPoint(wsId, null) + id);

    return data;
  }
);

export const setApplication = createAsyncThunk(
  'managementApp/applications/setApplication',
  async (data) => {
    return { id: data.id, changes: data };
  }
);

const applicationsAdapter = createEntityAdapter({});

export const { selectAll: selectApplications, selectById: selectApplicationById } =
  applicationsAdapter.getSelectors(({ managementApp }) => managementApp.applications);

export const selectApplicationData = ({ managementApp }) => managementApp.applications;

const initialState = applicationsAdapter.getInitialState({
  numOfPages: 0,
  count: 0,
  page: 1,
  isLoading: true,
  FiltersDialogProps: {
    open: false,
  },
});

const applicationsSlice = createSlice({
  name: 'managementApp/applications',
  initialState,
  reducers: {
    setNumOfPages: (state, action) => {
      state.numOfPages = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    openFiltersDialog: (state, action) => {
      state.FiltersDialogProps = {
        open: true,
      };
    },
    closeFiltersDialog: (state, action) => {
      state.FiltersDialogProps = {
        open: false,
      };
    },
    resetApplicationsState: () => initialState,
  },
  extraReducers: {
    [getApplications.fulfilled]: applicationsAdapter.setAll,
    [setApplication.fulfilled]: applicationsAdapter.updateOne,
    [getApplication.fulfilled]: applicationsAdapter.upsertOne,
  },
});

export const selectSelectedApplication = ({ managementApp }) =>
  managementApp.applications.selectedApplicationId;

export const {
  setNumOfPages,
  setIsLoading,
  setPage,
  resetApplicationsState,
  setCount,
  openFiltersDialog,
  closeFiltersDialog,
} = applicationsSlice.actions;

export default applicationsSlice.reducer;
