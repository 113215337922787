import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import { t } from 'i18next';

export const getAlertLocationList = createAsyncThunk(
  'LocationApp/MapData/Alerts/getAlertLocationList',
  async ({ ws, params }, { dispatch, getState }) => {
    const { data } = await axios.get(ApiRoutes.eventsEndPoint(ws, params, { all: true }));

    if (data.length === 0)
      dispatch(showMessage({ message: t('locationApp:NO_ALERTS_FOUND'), variant: 'warning' }));

    return data.map((e) => ({ ...e, typeMarker: 'Alert', visible: true }));
  }
);
export const deleteAlert = createAsyncThunk(
  'LocationApp/MapData/Alerts/deleteAlert',
  async (id, { dispatch, getState }) => id
);

const alertsAdapter = createEntityAdapter({});

export const { selectAll: selectAlertLists } = alertsAdapter.getSelectors(
  (state) => state.LocationApp.Alerts
);
const initialState = alertsAdapter.getInitialState({});

const alertSlice = createSlice({
  name: 'LocationApp/MapData/Alerts',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setVisibleAlert: (state, action) => {
      const { alertId, visible } = action.payload;
      state.entities[alertId].visible = visible;
    },
    logicalDeleteAlert: (state, action) => {
      const id = action.payload;
      state.entities[id].delete = true;
    },
    resetAlertState: () => initialState,
  },
  extraReducers: {
    [getAlertLocationList.fulfilled]: alertsAdapter.upsertMany,
    [deleteAlert.fulfilled]: alertsAdapter.removeOne,
  },
});

export const { setLoading, logicalDeleteAlert, setVisibleAlert, resetAlertState } =
  alertSlice.actions;

const { reducer } = alertSlice;

export default reducer;
