import * as yup from 'yup';
import Box from '@mui/material/Box/';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { format, parseISO } from 'date-fns';
import { motion } from 'framer-motion';
import { roleChecker } from 'app/store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { yupResolver } from '@hookform/resolvers/yup';
import { permissionChecker } from 'app/store/userWorkspacePermissionsSlice';
import { selectWorkspaceAux } from 'app/store/workspaceSlice';

import { showMessage } from 'app/store/fuse/messageSlice';
import {
  addForm,
  selectFormById,
  setIsEditing,
  setForm,
  formSelector,
  setDeleteModalProps,
  openDuplicateFormConfirmModal,
} from '../store/formsSlice';
import FieldItemDetails from './FieldItemDetails';
import FieldItemEdit from './FieldItemEdit';

const defaultValues = {
  name: '',
  data: {
    fields: [],
  },
};

function FormsSidebarContent(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const checkRole = useSelector(roleChecker);
  const checkPermission = useSelector(permissionChecker);

  const { t } = useTranslation('assignmentsApp');
  const { formId, wsId } = useParams();
  const form = useSelector((state) => selectFormById(state, formId));
  const AuxData = useSelector(selectWorkspaceAux);
  const { isEditing } = useSelector(formSelector);

  const onClose = () => navigate(`..${window.location.search}`);

  const onEdit = () => {
    reset(form);
    dispatch(setIsEditing(true));
  };

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    name: yup.string().required(t('NAME_CANNOT_EMPTY')),
  });

  const { control, formState, getValues, reset, setValue, watch } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const saveForm = async () => {
    if (!isValid) return;

    try {
      if (formId === 'new') {
        const [{ id }] = await dispatch(addForm({ wsId, form: getValues() })).unwrap();

        navigate(`../${id}${window.location.search}`, { replace: true });
      } else await dispatch(setForm({ wsId, form: getValues() })).unwrap();
    } catch (error) {
      reset(formId === 'new' ? defaultValues : form);
    }
  };

  const manualSaveForm = async () => {
    if (formId !== 'new') dispatch(setForm({ wsId, form: getValues() }));
    dispatch(setIsEditing(false));
  };

  const openDeleteModal = () => dispatch(setDeleteModalProps({ open: true, formId }));

  const openDuplicateModal = () => dispatch(openDuplicateFormConfirmModal(formId));

  const unlinkConditioned = (conditionedId) => {
    const fields = getValues('data.fields');

    const conditionedIndex = fields.findIndex((field) => field.id === conditionedId);
    const conditioned = fields[conditionedIndex];
    delete conditioned.has_conditional;
    delete conditioned.conditional_info;
    conditioned.show = true;

    setValue(`data.fields.${conditionedIndex}`, conditioned);
  };

  const unlinkConditioner = (conditionedId, conditionerId) => {
    const fields = getValues('data.fields');

    const conditionerIndex = fields.findIndex((field) => field.id === conditionerId);
    const conditioner = fields[conditionerIndex];

    conditioner.conditionals = conditioner.conditionals.filter(
      ({ field }) => field !== conditionedId
    );

    if (conditioner.conditionals.length === 0) delete conditioner.conditionals;

    setValue(`data.fields.${conditionerIndex}`, conditioner);
  };

  const linkConditioned = (conditionerId, conditionedId, valueId) => {
    const fields = getValues('data.fields');
    const conditionedIndex = fields.findIndex((field) => field.id === conditionedId);

    const conditioner = fields.find((field) => field.id === conditionerId);
    const conditioned = fields[conditionedIndex];

    conditioned.has_conditional = true;
    conditioned.conditional_info = {
      field_id: conditionerId,
      value_id: valueId,
      field_title: conditioner.title,
      field_value: '',
    };

    if (conditioner.type === 'ternary') {
      if (valueId === true) conditioned.conditional_info.field_value = 'si';
      else if (valueId === false) conditioned.conditional_info.field_value = 'no';
      else if (valueId === null) conditioned.conditional_info.field_value = '';
    } else {
      conditioned.conditional_info.field_value = conditioner.select_values.find(
        (option) => option.id === valueId
      ).value;
    }

    setValue(`data.fields.${conditionedIndex}`, conditioned);
  };

  const linkConditioner = (conditionerId, conditionedId, valueId) => {
    const fields = getValues('data.fields');
    const conditionerIndex = fields.findIndex((field) => field.id === conditionerId);
    const conditioner = fields[conditionerIndex];

    const conditionalData = {
      rule: {
        value: valueId,
        operator: '===',
      },
      field: conditionedId,
    };

    if (!conditioner.conditionals) {
      conditioner.conditionals = [conditionalData];
    } else {
      const conditionalconditionedIndex = conditioner.conditionals.findIndex(
        ({ field }) => field === conditionedId
      );

      if (conditionalconditionedIndex === -1) conditioner.conditionals.push(conditionalData);
      else {
        conditioner.conditionals[conditionalconditionedIndex] = conditionalData;
      }
    }

    setValue(`data.fields.${conditionerIndex}`, conditioner);
    updateConditioneds(conditionerId);
  };

  const updateConditioneds = (conditionerId) => {
    const fields = getValues('data.fields');
    const conditionerIndex = fields.findIndex((field) => field.id === conditionerId);

    if (!fields[conditionerIndex].conditionals) return;

    if (fields[conditionerIndex].type === 'select') {
      fields[conditionerIndex].conditionals.forEach((conditional) => {
        const conditionedIndex = fields.findIndex((field) => field.id === conditional.field);

        // the field conditioned should be seen
        const showField =
          conditional.rule.value ===
          fields[conditionerIndex].select_values.find(
            (value) => value.idx === fields[conditionerIndex].select_default_value
          )?.id;

        setValue(`data.fields.${conditionedIndex}.show`, showField);
      });
    } else if (fields[conditionerIndex].type === 'ternary') {
      fields[conditionerIndex].conditionals.forEach((conditional) => {
        const conditionedIndex = fields.findIndex((field) => field.id === conditional.field);
        setValue(
          `data.fields.${conditionedIndex}.show`,
          // the field conditioned should be seen
          fields[conditionerIndex].value === conditional.rule.value
        );
      });
    }
  };

  const onChangeField = (fieldData, oldFieldData, index) => {
    setValue(`data.fields.${index}`, fieldData);
    if (fieldData.conditionals) updateConditioneds(fieldData.id);

    if (oldFieldData.has_conditional)
      unlinkConditioner(fieldData.id, oldFieldData.conditional_info?.field_id);

    if (fieldData.has_conditional === true) {
      linkConditioned(
        fieldData.conditional_info.field_id,
        fieldData.id,
        fieldData.conditional_info.value_id
      );

      linkConditioner(
        fieldData.conditional_info.field_id,
        fieldData.id,
        fieldData.conditional_info.value_id
      );
    } else if (fieldData.has_conditional === false) {
      unlinkConditioned(fieldData.id);
    }

    saveForm();
  };

  const deleteFormField = (index) => {
    const fields = getValues('data.fields');

    const [removedField] = fields.splice(index, 1);

    // remove conditionals from conditioned
    if (removedField.conditionals)
      removedField.conditionals.forEach((conditional) => unlinkConditioned(conditional.field));

    // remove conditionals from conditioner
    if (removedField.has_conditional)
      unlinkConditioner(removedField.id, removedField.conditional_info.field_id);

    setValue('data.fields', fields);

    saveForm();
  };

  const moveFormField = ({ destination, source }) => {
    if (!destination) return;

    const { index: newIndex } = destination;
    const { index: oldIndex } = source;

    const fields = getValues('data.fields');

    if (newIndex >= fields.length) {
      let k = newIndex - fields.length + 1;
      while (k) {
        fields.push(undefined);
        k -= 1;
      }
    }
    fields.splice(newIndex, 0, fields.splice(oldIndex, 1)[0]);
    setValue('data.fields', fields);
    saveForm();
  };

  const addFormField = () => {
    const fieldsCount = getValues('data.fields').length;
    if (fieldsCount + 1 >= (AuxData?.assignments?.max_fields_per_form || 30))
      dispatch(showMessage({ message: t('FORM_MAXIMUM_FIELD'), variant: 'warning' }));
    setValue(`data.fields.${fieldsCount}`, {
      id: uuidv4().substring(0, 8),
      type: null,
      required: false,
      show: true,
      title: '',
    });
  };

  useEffect(() => {
    if (formId === 'new') {
      reset(defaultValues);
      dispatch(setIsEditing(true));
    } else if (isEditing) {
      reset(form);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, formId, isEditing]);

  const { isValid, dirtyFields, errors } = formState;

  if (!form && formId !== 'new') {
    return null;
  }

  return (
    <motion.div
      initial={{ y: 50, opacity: 0.8 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.3 } }}
      className="flex-auto flex flex-col px-12 h-fullVH w-fullVW sm:h-full sm:w-full"
    >
      <div className="flex items-center justify-end w-full">
        <Tooltip title={t('CLOSE')} placement="bottom">
          <IconButton className="" size="small" onClick={onClose}>
            <FuseSvgIcon>heroicons-solid:x</FuseSvgIcon>
          </IconButton>
        </Tooltip>
      </div>
      {!isEditing && Boolean(form) ? (
        <div>
          <Typography className="text-18 font-medium">{form?.name}</Typography>

          <Divider className="flex-1 px-8">
            <Typography color="textSecondary" variant="bold caption">
              {t('FIELDS')}
            </Typography>
          </Divider>
          <div className="">
            {form?.data.fields.map((field) => (
              <div key={field.id}>
                <FieldItemDetails field={field} className="my-10" />
                <Divider />
              </div>
            ))}

            {checkRole('platform_support') && (
              <>
                <div>
                  <Typography variant="caption" className="font-medium">
                    {t('CREATION_DATE')}:
                  </Typography>
                  <Typography variant="caption">
                    {format(parseISO(form?.date_created), 'dd/MM/y hh:mm aaaa ')}
                  </Typography>
                </div>
                <div>
                  <Typography variant="caption" className="font-medium">
                    {t('EDITION_DATE')}:
                  </Typography>
                  <Typography variant="caption">
                    {format(parseISO(form?.date_edited), 'dd/MM/y hh:mm aaaa ')}
                  </Typography>
                </div>
                <div>
                  <Typography variant="caption" className="font-medium">
                    ID:
                  </Typography>
                  <Typography variant="caption">{form?.id}</Typography>
                </div>
              </>
            )}
          </div>
          <div className="side-bar-float-buttons">
            {checkPermission('edit_form') && (
              <Tooltip title={t('EDIT_FORM')} placement="top">
                <Fab color="secondary" size="small" aria-label="edit" onClick={onEdit}>
                  <FuseSvgIcon size={20}>heroicons-solid:pencil</FuseSvgIcon>
                </Fab>
              </Tooltip>
            )}

            <Tooltip title={t('DUPLICATE_FORM')} placement="top">
              <Fab color="primary" size="small" aria-label="edit" onClick={openDuplicateModal}>
                <FuseSvgIcon size={20}>heroicons-solid:document-duplicate</FuseSvgIcon>
              </Fab>
            </Tooltip>

            {checkPermission('delete_form') && (
              <Tooltip title={t('DELETE_FORM')} placement="top">
                <Fab color="error" size="small" aria-label="remove" onClick={openDeleteModal}>
                  <FuseSvgIcon size={20}>material-solid:delete</FuseSvgIcon>
                </Fab>
              </Tooltip>
            )}
          </div>
        </div>
      ) : (
        <div>
          <form
            name="formForm"
            noValidate
            className="flex-auto flex flex-col justify-start w-full"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  id="name"
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                  label={t('NAME')}
                  onBlur={saveForm}
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                />
              )}
            />
            <div className="flex items-center">
              <div className="flex-1">
                <Divider textAlign="left">
                  <Typography variant="caption" color="GrayText">
                    {t('FIELDS')}
                  </Typography>
                </Divider>
              </div>
            </div>
            <DragDropContext onDragEnd={moveFormField}>
              <Droppable droppableId="droppableCardBody" direction="vertical">
                {(provided) => (
                  <Box ref={provided.innerRef} {...provided.droppableProps}>
                    {watch('data.fields').map((field, index) => (
                      <Draggable index={index} draggableId={field.id} key={field.id} type="list">
                        {(providedChild, snapshot) => (
                          <Box
                            ref={providedChild.innerRef}
                            {...providedChild.draggableProps}
                            className="relative group"
                          >
                            <div
                              className="md:hidden absolute m-10 top-0 left-0 cursor-move group-hover:flex"
                              {...providedChild.dragHandleProps}
                            >
                              <Tooltip title={t('MOVE_FIELD')} placement="top">
                                <FuseSvgIcon sx={{ color: 'secondary.main' }} size={20}>
                                  material-outline:open_with
                                </FuseSvgIcon>
                              </Tooltip>
                            </div>
                            <FieldItemEdit
                              key={field.id}
                              field={{ ...field }}
                              onChangeField={(fieldData, oldFieldData) =>
                                onChangeField(fieldData, oldFieldData, index)
                              }
                              onDelete={() => deleteFormField(index)}
                              className="my-10 border-dotted border-3 !border-purple-900 shadow-lg rounded-16 pl-36 p-6 pb-12 bg-grey-200"
                            />
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </form>
          <div className="side-bar-float-buttons">
            <Tooltip title={t('ADD_FIELD')} placement="top">
              <Fab
                color="success"
                size="small"
                aria-label="remove"
                onClick={addFormField}
                disabled={
                  watch('data.fields').length >= (AuxData?.assignments?.max_fields_per_form || 30)
                }
              >
                <FuseSvgIcon size={20}>material-solid:add_circle</FuseSvgIcon>
              </Fab>
            </Tooltip>
            <Tooltip title={t('SAVE_FORM')} placement="top">
              <Fab color="secondary" size="small" aria-label="edit" onClick={manualSaveForm}>
                <FuseSvgIcon size={20}>material-solid:save</FuseSvgIcon>
              </Fab>
            </Tooltip>
          </div>
        </div>
      )}
    </motion.div>
  );
}

export default FormsSidebarContent;
