/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Controller, useFormContext } from 'react-hook-form';
import { selectFormById } from 'src/app/pages/modulesApp/assignmentsApp/store/formsSlice';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

export default function FieldSelectMultiple() {
  const { t } = useTranslation('assignmentsApp');
  const { formId } = useParams();
  const form = useSelector((state) => selectFormById(state, formId));
  const { control, formState, watch, getValues, setValue, reset } = useFormContext();
  const { errors } = formState;

  const field = getValues();

  const moveUpOption = (index) => {
    const tmpOption = field.select_values[index];

    setValue(`select_values.${index}`, field.select_values[index - 1]);
    setValue(`select_values.${index - 1}`, tmpOption);
  };

  const moveDownOption = (index) => {
    const tmpOption = field.select_values[index];

    setValue(`select_values.${index}`, field.select_values[index + 1]);
    setValue(`select_values.${index + 1}`, tmpOption);
  };

  const addField = () =>
    setValue(
      `select_values.${field.select_values.length}`,
      {
        id: uuidv4().substring(0, 8),
        value: '',
        checked: false,
      },
      { shouldValidate: true }
    );

  const removeOption = (index) => {
    if (field.select_values[index].checked) {
      field.select_default_values = field.select_default_values.filter(
        (option) => option.id !== field.select_values[index].id
      );
    }

    field.select_values.splice(index, 1);

    setValue('select_values', field.select_values);
  };

  const setChecked = (checked, index) => {
    const newField = getValues();
    setValue(`select_values.${index}.checked`, checked);
    setValue(
      `select_default_values`,
      newField.select_values.filter((option) => option.checked)
    );
  };

  return (
    <>
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full py-5 px-10">
          <div className="flex flex-col flex-1 w-full items-start justify-between">
            <Typography variant="subtitle1" color="inherit">
              <b>{t('FIELD_TYPE')}</b>:{t('SELECT_MULTIPLE_TITLE')}
            </Typography>
            <Typography variant="caption">{t('SELECT_MULTIPLE_DEPS')}</Typography>
          </div>
          <img className="h-48" src="assets/images/fields/select-multiple.png" alt="text" />
        </Toolbar>
      </AppBar>
      <div className="flex flex-col px-10 py-16 gap-y-20 overflow-y-auto h-360">
        <Controller
          name="title"
          control={control}
          render={({ field: fieldI }) => (
            <TextField
              {...fieldI}
              fullWidth
              size="small"
              variant="outlined"
              id={`textFieldTitle${fieldI.id}`}
              label={t('TITLE')}
              error={!!errors.title}
              helperText={errors?.title?.message}
            />
          )}
        />
        <div>
          {watch('select_values').map((select, index) => (
            <div
              key={index}
              className="flex justify-between items-center border-solid border-2 border-cyan-600 shadow-lg rounded-16 p-6 m-5"
            >
              <div className="flex items-center">
                <Tooltip title={t('CHECK_BY_DEFAULT')} placement="top">
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                    id={`selectMultipleCheck${select.id}`}
                    checked={select.checked}
                    onChange={(e, checked) => setChecked(checked, index)}
                  />
                </Tooltip>
                <TextField
                  fullWidth
                  autoFocus={index !== 0 && !select.value}
                  value={select.value}
                  id={`selectMultipleLabel${select.id}`}
                  variant="outlined"
                  size="small"
                  placeholder={t('WRITE_HERE_THE_OPTION')}
                  error={!!errors.select_values?.[index]?.value}
                  helperText={errors?.select_values?.[index]?.value?.message}
                  onChange={(e) =>
                    setValue(`select_values.${index}.value`, e.target.value, {
                      shouldValidate: true,
                    })
                  }
                />
              </div>
              {watch('select_values').length > 1 && (
                <div className="flex">
                  <Tooltip title={t('MOVE_UP_OPTION')} placement="top">
                    <span>
                      <IconButton
                        className="min-w-0"
                        variant="contained"
                        size="small"
                        disabled={index === 0}
                        onClick={() => moveUpOption(index)}
                      >
                        <FuseSvgIcon className="text-48" size={24}>
                          material-solid:keyboard_arrow_up
                        </FuseSvgIcon>
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={t('MOVE_DOWN_OPTION')} placement="top">
                    <span>
                      <IconButton
                        className="min-w-0"
                        variant="contained"
                        size="small"
                        disabled={index === field.select_values.length - 1}
                        onClick={() => moveDownOption(index)}
                      >
                        <FuseSvgIcon className="text-48" size={24}>
                          material-solid:keyboard_arrow_down
                        </FuseSvgIcon>
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={t('REMOVE_OPTION')} placement="top">
                    <IconButton
                      className="min-w-0"
                      variant="contained"
                      size="small"
                      onClick={() => removeOption(index)}
                    >
                      <FuseSvgIcon className="text-48" size={24} color="error">
                        heroicons-solid:x-circle
                      </FuseSvgIcon>
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="flex justify-end">
          <Button
            variant="contained"
            color="success"
            size="small"
            className="my-5"
            startIcon={<FuseSvgIcon size={18}>material-solid:add_circle</FuseSvgIcon>}
            onClick={addField}
          >
            {t('ADD_FIELD')}
          </Button>
        </div>
        <Controller
          name="required"
          control={control}
          render={({ field: { onChange, value, ref, onBlur } }) => (
            <FormControlLabel
              control={
                <Switch
                  id="requiredSwitch"
                  size="small"
                  checked={value}
                  onBlur={onBlur}
                  onChange={(ev) => onChange(ev.target.checked)}
                  inputRef={ref}
                />
              }
              label={t('REQUIRED')}
            />
          )}
        />
        <Divider />
        <Controller
          name="has_conditional"
          control={control}
          render={({ field: { onChange, value, ref, onBlur } }) => (
            <FormControlLabel
              control={
                <Switch
                  id="hasConditionalSwitch"
                  size="small"
                  checked={value || false}
                  onBlur={onBlur}
                  onChange={(ev) => onChange(ev.target.checked)}
                  inputRef={ref}
                />
              }
              label={t('CONDITIONALLY_SHOW_FIELD')}
            />
          )}
        />

        {watch('has_conditional') && (
          <Controller
            render={({ field: fieldI }) => (
              <FormControl fullWidth size="small">
                {form.data.fields.filter(
                  (fieldII) => fieldII.type === 'select' || fieldII.type === 'ternary'
                ).length ? (
                  <>
                    <InputLabel id="conditional_info">{t('CONDITIONING_FIELD')}</InputLabel>
                    <Select
                      {...fieldI}
                      variant="outlined"
                      fullWidth
                      labelId="conditional_info"
                      id="conditional_info"
                      name="conditional_info"
                      label={t('CONDITIONING_FIELD')}
                      value={fieldI.value?.field_id || ''}
                      onChange={(e) => fieldI.onChange({ field_id: e.target.value })}
                      defaultValue=""
                    >
                      {form.data.fields
                        .filter(
                          (fieldII) => fieldII.type === 'select' || fieldII.type === 'ternary'
                        )
                        .map((fieldII) => (
                          <MenuItem key={fieldII.id} value={fieldII.id}>
                            <div className="flex justify-between w-full">
                              <Typography variant="caption">{fieldII.title}</Typography>
                              <div className="flex h-20">
                                {fieldII.type === 'ternary' ? (
                                  <img src="assets/images/fields/ternary.png" alt="text" />
                                ) : (
                                  <img src="assets/images/fields/select.png" alt="text" />
                                )}
                              </div>
                            </div>
                          </MenuItem>
                        ))}
                    </Select>
                  </>
                ) : (
                  <Typography variant="caption">{t('NO_CONDITIONING_FIELD')}</Typography>
                )}
              </FormControl>
            )}
            name="conditional_info"
            control={control}
          />
        )}
        {watch('conditional_info')?.field_id && watch('has_conditional') && (
          <Controller
            render={({ field: fieldI }) => (
              <FormControl fullWidth size="small">
                <InputLabel id="conditional_info_field_id">{t('CONDITIONING_VALUE')}</InputLabel>
                {form.data.fields.find((fieldII) => fieldII.id === fieldI.value?.field_id)?.type ===
                'ternary' ? (
                  <Select
                    {...fieldI}
                    variant="outlined"
                    fullWidth
                    labelId="conditional_info_field_id"
                    id="conditional_info_field_id"
                    name="conditional_info_field_id"
                    label={t('CONDITIONING_VALUE')}
                    value={JSON.stringify(fieldI.value?.value_id) || ''}
                    onChange={(e) =>
                      fieldI.onChange({ ...fieldI.value, value_id: JSON.parse(e.target.value) })
                    }
                  >
                    <MenuItem key="null" value="null">
                      {t('NO_RESPONSE')}
                    </MenuItem>
                    <MenuItem key="false" value="false">
                      {t('NO')}
                    </MenuItem>
                    <MenuItem key="true" value="true">
                      {t('YES')}
                    </MenuItem>
                  </Select>
                ) : (
                  <Select
                    {...fieldI}
                    variant="outlined"
                    fullWidth
                    labelId="conditional_info_field_id"
                    id="conditional_info_field_id"
                    name="conditional_info_field_id"
                    label={t('CONDITIONING_VALUE')}
                    value={fieldI.value?.value_id || ''}
                    onChange={(e) => fieldI.onChange({ ...fieldI.value, value_id: e.target.value })}
                  >
                    {form.data.fields
                      .find((fieldII) => fieldII.id === fieldI.value?.field_id)
                      .select_values.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.value}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              </FormControl>
            )}
            name="conditional_info"
            control={control}
          />
        )}
      </div>
    </>
  );
}
