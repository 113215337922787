import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import FuseUtils from '@fuse/utils';
import Card from '@mui/material/Card';

import Typography from '@mui/material/Typography';
import { selectWorkspace } from 'app/store/workspaceSlice';
import clsx from 'clsx';

import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function NotificationCard(props) {
  const { item, className } = props;
  const { t } = useTranslation('navigation');
  const { id } = useSelector(selectWorkspace);

  const openNotification = () =>
    window.open(`${window.location.origin}/ws/${id}/notifications/${item.id}`, '_blank');

  return (
    <Card
      className={clsx(
        'flex relative w-full rounded-4 shadow items-stretch mb-10 text-black cursor-pointer',
        FuseUtils.notificationEnum[item.level].backgroundColor
      )}
      elevation={0}
      onClick={openNotification}
    >
      <div className={clsx('flex w-10', FuseUtils.notificationEnum[item.level].color, className)} />
      <div className="flex flex-col flex-1 px-8 py-8">
        <div className="flex items-center justify-start gap-8">
          <FuseSvgIcon className="text-48" size={20} color="action">
            {FuseUtils.notificationEnum[item.level].icon}
          </FuseSvgIcon>
          <div>
            <Typography className="font-500 text-15 leading-none">{item.title}</Typography>
            <Typography className="text-10 text-grey-400">
              {t(`${item.type.toUpperCase()}_DESP`)}
            </Typography>
          </div>
        </div>
        <Typography className="text-12 text-grey-600 my-10">{item.message}</Typography>
        <div className="flex justify-end">
          <Typography className="mt-8 text-sm leading-none text-grey-400" color="text.secondary">
            {formatDistanceToNow(new Date(item.date_created), { addSuffix: true })}
          </Typography>
        </div>
      </div>
    </Card>
  );
}

export default NotificationCard;
