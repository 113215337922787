import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import withReducer from 'app/store/withReducer';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { useEffect } from 'react';
import ApiRoutes from 'src/app/services/ApiRoutes';
import axios from 'axios';
import { selectWorkspace } from 'app/store/workspaceSlice';
import reducer from './store';
import {
  setNotificationCount,
  selectNotificationPanelState,
  toggleNotificationPanel,
  getNotifications,
} from './store/NotificationSlice';

function NotificationPanelToggleButton({
  children = <FuseSvgIcon>heroicons-outline:bell</FuseSvgIcon>,
}) {
  const { notificationCount } = useSelector(selectNotificationPanelState);
  const { id } = useSelector(selectWorkspace);

  const dispatch = useDispatch();

  useEffect(() => {
    let timeout;
    const getCountNotifications = async () => {
      try {
        const {
          data: { count },
        } = await axios.get(`${ApiRoutes.notificationsEndPoint(id)}unchecked/`);
        dispatch(setNotificationCount(count));
      } catch (error) {
        console.log(error);
      }
      timeout = setTimeout(getCountNotifications, 6e5);
    };

    getCountNotifications();
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOpenNotifications = () => {
    dispatch(toggleNotificationPanel());
    dispatch(getNotifications({ ws: id }));
  };

  return (
    <IconButton className="w-40 h-40" onClick={onOpenNotifications} size="large">
      <Badge
        color="secondary"
        invisible={notificationCount === 0}
        badgeContent={notificationCount}
        max={9}
      >
        {children}
      </Badge>
    </IconButton>
  );
}

export default withReducer('notificationPanel', reducer)(NotificationPanelToggleButton);
