import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';

function DocumentationButton({ className }) {
  const { t } = useTranslation('navigation');

  return (
    <Tooltip title={t('DOCUMENTATION')} placement="bottom">
      <IconButton
        component={Link}
        to={process.env.REACT_APP_DOCS_LINK}
        target="_blank"
        role="button"
        className={className}
        variant="outlined"
        color="primary"
      >
        <FuseSvgIcon>heroicons-outline:book-open</FuseSvgIcon>
      </IconButton>
    </Tooltip>
  );
}

export default DocumentationButton;
